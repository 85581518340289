import { useDispatch, useSelector } from "react-redux";
import React, { ChangeEvent, useEffect, ReactElement } from "react";
import { ApplicationState } from '../../ApplicationState';
import { useHistory } from 'react-router-dom';
import { login } from './LoginStore';
import { allAppRoutes } from "../../common/commonInterfaces/allAppRoutes";
import { ErrorMessagesTypes, loading, isEmailValidate, validateEmail, validateFormEmail, } from "aseguisShared";
import RegisterTemplate from "../Register/RegisterTemplate";
import imageLogo from "../../assets/logoSoloAseguis_2.png";
import './../Register/Register.css';
import SeoLogin from "./LoginSeo";

function Login(): ReactElement {

    const dispatch: React.Dispatch<any> = useDispatch();
    const history = useHistory()

    const loginLoading: number = useSelector((state: ApplicationState) => state.login.loginLoaded);
    const errorToShow: string = useSelector((state: ApplicationState) => state.login.errorToShow);
    const typeError: string = useSelector((state: ApplicationState) => state.login.typeError);

    const [formEmail, setFormEmail] = React.useState("")
    const [formPassword, setFormPassword] = React.useState("")

    const isAuthenticated = !!localStorage.getItem('refreshToken');

    function emailChanged(event: ChangeEvent<HTMLInputElement>): void {
        var value = validateEmail(event.target.value)
        setFormEmail(value)
    }

    function passwordChanged(event: ChangeEvent<HTMLInputElement>): void {
        setFormPassword(event.target.value.trimStart())
    }

    const doLogIn = () => {
        if (isEmailValidate(formEmail)) {
            dispatch(login(formEmail, formPassword));
        }
    }

    useEffect(() => {
        if (isAuthenticated) {
            history.push(allAppRoutes.leftMenuPanel);
        }
    }, [isAuthenticated]);


    return (

        <RegisterTemplate>

            <SeoLogin />

            <div className="login-container">

                <img src={imageLogo} alt="Logo" className="register-logo" />

                <div className="text-center">
                    <h1 className="h3 text-gray-900 mb-2">¡Bienvenido a <span
                        style={{ fontWeight: "bold" }}>Aseguis</span>!</h1>
                    <h5 className="h4 text-gray-900 mb-3"
                        style={{ fontSize: '0.8rem', color: '#6c757d', marginBottom: '16px' }}>
                        {"¿Aún no tienes cuenta? "}
                        <a
                            onClick={(event) => {
                                event.preventDefault()
                                history.push(allAppRoutes.register);
                            }}
                            href="/register" style={{ color: '#007bff', textDecoration: 'none' }}>
                            Crea una aquí
                        </a>
                    </h5>
                </div>

                <br></br>

                <form>

                    <div className="form-group">
                        <input
                            id="register-input emailLogin login"
                            name="emailLogin"
                            placeholder={"Correo electrónico"}
                            type="text"
                            autoComplete="off"
                            className={formEmail.length > 0 ? validateFormEmail(formEmail) : "form-control undefined"}
                            value={formEmail}
                            required
                            onChange={emailChanged}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    doLogIn()
                                }
                            }}
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="password"
                            className={"form-control undefined"}
                            id="id_Password"
                            autoComplete="off"
                            placeholder={"Contraseña"}
                            value={formPassword}
                            onChange={passwordChanged}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    doLogIn()
                                }
                            }}
                        />
                    </div>

                    {/*<div className="form-group">
                                            <div className="custom-control custom-checkbox small">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="customCheck"
                                                    checked={saveLogin}
                                                    onChange={() => {
                                                        setSaveLogin(saveLogin === true ? false : true)
                                                    }}

                                                />
                                                <label className="custom-control-label"
                                                    htmlFor="customCheck">Recuérdame</label>
                                            </div>
                                        </div>*/}

                    {(errorToShow && typeError === ErrorMessagesTypes.nok) &&
                        <div className="alert alert-danger" style={{ display: 'flex', justifyContent: 'center' }}>
                            {errorToShow}
                        </div>
                    }

                    {(errorToShow && typeError === ErrorMessagesTypes.ok) &&
                        <div className="alert alert-success " style={{ display: 'flex', justifyContent: 'center' }}>
                            {errorToShow}
                        </div>
                    }

                    <br></br>

                    <div className="text-center mb-2">
                        <h5 className="h4 text-gray-900 mb-3" style={{ fontSize: '0.8rem', color: '#6c757d', marginBottom: '16px' }}>
                            <a
                                onClick={(event) => {
                                    event.preventDefault()
                                    history.push(allAppRoutes.forgotPassword);
                                }}
                                href="/forgotPassword" style={{ color: '#007bff', textDecoration: 'none' }}>
                                ¿Has olvidado la contraseña?
                            </a>
                        </h5>
                    </div>

                    {(loginLoading === loading.done) ?
                        <div>
                            <button
                                className={"btn btn-primary btn-user btn-block"}
                                type="button"
                                onClick={doLogIn}
                            >
                                <span>Iniciar sesión</span>
                            </button>
                        </div>
                        :
                        <div>
                            <button
                                className={"btn btn-primary btn-user btn-block"}
                                type="button"
                            >
                                <span>Iniciando...</span>
                                <span className="spinner-border spinner-border-sm ml-2"></span>
                            </button>
                        </div>
                    }

                </form>

            </div>

        </RegisterTemplate>

    );
}


export default Login;
