

export enum allAppRoutes {

    //rutas aviso legal y privacidad
    avisoLegal = "https://www.aseguis.es/legal/terminosCondiciones/",
    politicaPrivacidad = "https://www.aseguis.es/legal/politicaPrivacidad/",
    politicaCookies = "https://www.aseguis.es/legal/politicaCookies/",

    //registro
    register = "/register/",
    enableLink = "/enableLink/:enableLink/",

    //ruta tracking
    tracking = "/tracking/:tenantId/:clientId/:shipmentID/",

    //rutas login, forgotPassword y newPassword
    login = "/login/",
    forgotPassword = "/forgotPassword/",
    newPassword = "/newPassword/:forgotLink/",

    //ruta padre admin
    admin = "/admin/",

    //rutas del menu izquierdo
    leftMenuPanel = "/admin/panel/",
    leftMenuCompany = "/admin/company/",
    leftMenuClients = "/admin/clients/",
    leftMenuEmployees = "/admin/employees/",
    leftMenuSuppliers = "/admin/suppliers/",
    leftMenuSettings = "/admin/settings/",
    leftMenuVehicles = "/admin/vehicles/",
    leftMenuVehiclesControls = "/admin/vehiclesControls/",
    leftMenuVehiclesReviRepa = "/admin/vehiclesReviRepa/",
    leftMenuGrupajes = "/admin/grupajes/",
    leftMenuCargaCompleta = "/admin/cargaCompleta/",
    leftMenuPaqueteria = "/admin/paqueteria/",
    leftMenuBills = "/admin/bills/",
    leftMenuScan = "/admin/scan/",
    leftMenuRemesa = "/admin/remesa/",
    leftMenuAbonos = "/admin/abonos/",

    //rutas company
    myUser = "/admin/myUser/",

    //rutas company
    companyForm = "/admin/company/form/",
    companyTariffs = "/admin/company/tariffs/",

    //rutas clients
    clientsList = "/admin/clients/list/",
    clientsForm = "/admin/clients/form/",
    clientsTariffs = "/admin/clients/tariffs/",

    //rutas employees
    employeesList = "/admin/employees/list/",
    employeesForm = "/admin/employees/form/",

    //rutas suppliers
    suppliersList = "/admin/suppliers/list/",
    suppliersForm = "/admin/suppliers/form/",

    //rutas vehicles
    vehiclesList = "/admin/vehicles/list/",
    vehiclesForm = "/admin/vehicles/form/",

    //rutas vehicles controls
    vehiclesControlsList = "/admin/vehiclesControls/list/",
    vehiclesControlsForm = "/admin/vehiclesControls/form/",

    //rutas vehicles revi Repa
    vehiclesReviRepaList = "/admin/vehiclesReviRepa/list/",
    vehiclesReviRepaForm = "/admin/vehiclesReviRepa/form/",

    //rutas settings
    settingsVehicleTypes = "/admin/settings/vehicleTypes/",
    settingsVehicleControlsTypes = "/admin/settings/vehicleControlsTypes/",
    settingsSuscription = "/admin/settings/suscription/",
    settingsIncidencias = "/admin/settings/incidences/",

    //rutas vehicles grupajes
    grupajesList = "/admin/grupajes/list/",
    grupajesForm = "/admin/grupajes/form/",

    //rutas vehicles carga completa
    cargaCompletaList = "/admin/cargaCompleta/list/",
    cargaCompletaForm = "/admin/cargaCompleta/form/", // + id

    //rutas vehicles paqueteria
    paqueteriaList = "/admin/paqueteria/list/",
    paqueteriaForm = "/admin/paqueteria/form/",

    //facturas
    billsList = "/admin/bills/list/",
    billsAddForm = "/admin/bills/Addform/",
    billsForm = "/admin/bills/form/",

    //rutas clients
    abonosList = "/admin/abonos/list/",
    abonosForm = "/admin/abonos/form/",
}