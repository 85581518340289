import React, {Fragment, useState} from "react";
import {Button, Modal, Form} from "react-bootstrap";
import Select, {MultiValue} from "react-select";
import {Notify} from "../../common/Toastify/Notify";
import Swal from "sweetalert2";
import {cargaCompleta, inicialiceCargaCompleta, loginUsers} from "aseguisshared";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../ApplicationState";
import {createCargaCompletaApi} from "./CargaCompletaStore";

interface CargaCompletaRepeatModalProps {
    modalOpened: boolean;
    setModalOpened: (opened: boolean) => void;
}

interface OptionType {
    value: string;
    label: string;
}

const CargaCompletaRepeatModal = (props: CargaCompletaRepeatModalProps) => {

    const cargaForm: cargaCompleta = useSelector((state: ApplicationState) => state.cargaCompleta.cargaCompletaForm);
    const userLogged: loginUsers = useSelector((state: ApplicationState) => state.login.userLogged);

    const daysOptions = [
        {value: "monday", label: "Lunes"},
        {value: "tuesday", label: "Martes"},
        {value: "wednesday", label: "Miércoles"},
        {value: "thursday", label: "Jueves"},
        {value: "friday", label: "Viernes"},
        {value: "saturday", label: "Sábado"},
        {value: "sunday", label: "Domingo"}
    ];

    const [isModalLoading, setIsModalLoading] = useState(false);

    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [selectedDays, setSelectedDays] = useState<MultiValue<OptionType>>([]);
    const [keepReferences, setKeepReferences] = useState(false);


    const createOnApi = async (newDates: (string | number)[]) => {
        try {
            for (const newDate of newDates) {
                const parsedDate = new Date(newDate);

                // Asegurarse de que la fecha es válida
                if (isNaN(parsedDate.getTime())) {
                    console.error("Fecha inválida:", newDate);
                    return Notify("Error al crear albaranes", "error");
                }

                const cargaCompleta: cargaCompleta = {
                    ...inicialiceCargaCompleta,
                    datosGenerales: {
                        ...cargaForm.datosGenerales,
                        recogida: new Date(parsedDate.setHours(23, 59, 59, 999)),
                        entrega: new Date(parsedDate.setHours(23, 59, 59, 999)),
                        fecAlta: new Date(parsedDate.setHours(23, 59, 59, 999)),
                        referencias: keepReferences ? cargaForm.datosGenerales.referencias : [],
                        referenciaString: keepReferences ? cargaForm.datosGenerales.referenciaString : "",
                        user: userLogged,
                    },
                    datosEconomicos: cargaForm.datosEconomicos,
                    lineasViaje: cargaForm.lineasViaje,
                    idBill: 0,
                    idCargaRender: null,
                    idAbono: "",
                };
                const response = await createCargaCompletaApi(cargaCompleta);
                console.log("albaranes creados:", response);
            }
        } catch (error) {
            // Manejar el error si ocurre
            console.error("Error al llamar a la API:", error);
        }
    };


    const getWeekdayNumber = (day: string) => {
        const weekdays: { [key: string]: number } = {
            monday: 1,
            tuesday: 2,
            wednesday: 3,
            thursday: 4,
            friday: 5,
            saturday: 6,
            sunday: 0,// Sunday is 0 in JavaScript's Date
        };
        return weekdays[day];
    };

    const calculateRepetitionDays = (selectedDays: string[], endDate: string): string[] => {
        const initDate = new Date(startDate);
        const finalDate = new Date(endDate);
        const daysToRepeat: string[] = [];

        // Convertimos los días seleccionados a sus números equivalentes
        const selectedDayNumbers = selectedDays.map(day => getWeekdayNumber(day));

        let currentDate = new Date(initDate);

        // Iteramos por cada día desde la fecha actual hasta la fecha final, incluyendo el día final
        while (currentDate <= finalDate || currentDate.toDateString() === finalDate.toDateString()) {
            const dayOfWeek = currentDate.getDay(); // Obtenemos el día de la semana actual (0 = domingo, 1 = lunes, etc.)

            // Si el día de la semana actual coincide con uno de los días seleccionados, lo añadimos
            if (selectedDayNumbers.includes(dayOfWeek)) {

                //const formattedDate = currentDate.toLocaleDateString('es-ES'); // Formateamos la fecha en formato DD/MM/YYYY
                const formattedDate = currentDate.toISOString().split('T')[0]; // YYYY-MM-DD

                daysToRepeat.push(formattedDate);
            }

            // Incrementamos la fecha actual en un día
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return daysToRepeat;
    };


    const handleForm = () => {
        if (!startDate || !endDate || selectedDays.length === 0) {
            Notify("Por favor selecciona una fecha y al menos un día de la semana", "warn");
            return;
        }

        if (new Date(startDate) > new Date(endDate)) {
            Notify("La fecha de inicio no puede ser posterior a la fecha de finalización", "warn");
            return;
        }

        const selectedDaysArray = selectedDays.map((day) => day.value); // Extraemos los valores seleccionados
        const repetitionDates = calculateRepetitionDays(selectedDaysArray, endDate); // Calculamos las fechas de repetición


        // Mostrar un resumen en SweetAlert con el total de fechas
        Swal.fire({
            title: '¿Confirmar la repetición del albarán?',
            html: `
                <div style="text-align: center;">
                    <p style="font-size: 1.2em; margin-top: 15px;">Total de fechas en las que se repetirá el albarán:</p>
                    <div 
                        style="font-size: 2em; font-weight: bold; background-color: #f0f0f0; padding: 10px; border-radius: 8px;"
                        data-tip=""
                        data-for="repetitionDatesTooltip"
                    >
                        ${repetitionDates.length}
                    </div>
                    ${repetitionDates.length > 0
                ? `<p style="margin-top: 20px;">
                    <strong>
                    <br>
                                         
                    Haz clic en "Confirmar" para continuar
                    </strong></p>`
                : ''
            }
                </div>
            `,
            icon: 'info',
            width: 800,
            showCancelButton: true,
            showConfirmButton: repetitionDates.length > 0,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            customClass: {
                confirmButton: 'btn btn-success btn-lg px-4 mx-1',
                cancelButton: 'btn btn-danger btn-lg px-4 mx-1',
            },
            cancelButtonColor: '#6c757d',
            buttonsStyling: false,
        }).then(async (result) => {
            if (result.isConfirmed) {
                setIsModalLoading(true)
                try {
                    await createOnApi(repetitionDates)
                    Notify("Albaránes creados correctamente", "success");
                    closeModal();
                } catch (error) {
                    Notify("Error al repetir el albarán, vuelva a intentarlo", "error");
                    closeModal();
                }
                setIsModalLoading(false)
            }
        });
    };

    const closeModal = () => {
        setStartDate("");
        setEndDate("");
        setSelectedDays([]);
        setKeepReferences(false);
        props.setModalOpened(false);
    };

    return (
        <Fragment>
            <Modal show={props.modalOpened} onHide={closeModal} dialogClassName="modal-lg">
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">¿Qué días deseas repetir este albarán?</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <div className="container">

                        <div className="row mb-4">

                            <div className="col-md-6">
                                <Form.Group controlId="endDate">
                                    <Form.Label className="fw-semibold text-muted" column={"lg"}>
                                        Fecha desde (incluida):
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={startDate}
                                        min={new Date().toISOString().split("T")[0]} // Evitar fechas anteriores al día actual
                                        onChange={(e) => setStartDate(e.target.value)}
                                        className="shadow-sm"
                                    />
                                </Form.Group>
                            </div>

                            <div className="col-md-6">
                                <Form.Group controlId="endDate">
                                    <Form.Label className="fw-semibold text-muted" column={"lg"}>
                                        Fecha hasta (incluida):
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={endDate}
                                        min={startDate || new Date().toISOString().split("T")[0]} // La fecha mínima será la fecha de inicio
                                        onChange={(e) => setEndDate(e.target.value)}
                                        className="shadow-sm"
                                        disabled={!startDate}
                                    />
                                </Form.Group>
                            </div>

                        </div>

                        {/* Días de la semana */}
                        <div className="row mb-4">
                            <div className="col-md-12">
                                <Form.Group controlId="daysSelect">
                                    <Form.Label className="fw-semibold text-muted" column={"lg"}>Días de la
                                        semana:</Form.Label>
                                    <Select
                                        id="daysSelect"
                                        isMulti
                                        placeholder={"Selecciona los días de la semana"}
                                        options={daysOptions}
                                        value={selectedDays}
                                        onChange={setSelectedDays}
                                        className="basic-multi-select shadow-sm"
                                        classNamePrefix="select"
                                    />
                                </Form.Group>
                            </div>
                        </div>


                        {/* Nueva fila para los checkboxes */}
                        <div className="row m-4 mt-5">
                            <div className="col-md-6">
                                <Form.Check
                                    type="checkbox"
                                    id="keepReferences"
                                    label="Mantener mismas referencias"
                                    checked={keepReferences}
                                    onChange={(e) => setKeepReferences(e.target.checked)}
                                />
                            </div>
                        </div>

                    </div>
                </Modal.Body>

                <Modal.Footer>

                    {(isModalLoading) &&
                        <span className="spinner-border spinner-border-sm mr-4"></span>
                    }

                    <Button
                        type="submit"
                        variant="success"
                        className="px-4"
                        onClick={() => handleForm()}
                        disabled={!startDate || !endDate || selectedDays.length === 0} // Deshabilitar si falta alguna selección
                    >
                        Crear nuevos albaranes
                    </Button>

                    <Button
                        type="submit"
                        variant="info"
                        className="px-4"
                        onClick={async () => {
                            setIsModalLoading(true)
                            await createOnApi([new Date().getTime()])
                            setIsModalLoading(false)
                            Notify("Nuevo albarán creado correctamente", "success");
                            closeModal()
                        }}
                    >
                        Solo hoy
                    </Button>

                    <Button variant="secondary" onClick={closeModal}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>

        </Fragment>
    );
};

export default CargaCompletaRepeatModal;
