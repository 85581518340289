import React from "react";
import { Helmet } from "react-helmet-async";

const SeoRegister = () => (
    <Helmet>
        <html lang="es" />
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://app.aseguis.es/register/" />

        <title>Registro - Aseguis</title>
        <meta
            name="description"
            content="Regístrate en Aseguis y empieza a gestionar tus transportes."
        />
        <meta
            name="keywords"
            content="registro, Aseguis, software transporte, gestión transporte"
        />
        <meta name="robots" content="index, follow" />

        {/* OpenGraph */}
        <meta property="og:title" content="Registro - Aseguis" />
        <meta
            property="og:description"
            content="Regístrate en Aseguis y empieza a gestionar tus transportes."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://app.aseguis.es/register/" />
        <meta property="og:image" content="https://play-lh.googleusercontent.com/RsLK_NGZdnBvVpNXcUEHfA3ZzC62EOZo97JNx8RhcEBXPtKCrblj6iiu61RcX59lDNWD=w600-h300-pc0xffffff-pd" />

        {/* Twitter Cards */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Registro - Aseguis" />
        <meta
            name="twitter:description"
            content="Crea tu cuenta en Aseguis para gestionar tus transportes."
        />
        <meta name="twitter:image" content="https://play-lh.googleusercontent.com/RsLK_NGZdnBvVpNXcUEHfA3ZzC62EOZo97JNx8RhcEBXPtKCrblj6iiu61RcX59lDNWD=w600-h300-pc0xffffff-pd" />

        {/* Script JSON-LD para la página Register */}
        <script type="application/ld+json">
            {`
        {
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Registro",
          "description": "Página de registro en Aseguis para nuevas cuentas.",
          "url": "https://app.aseguis.es/register/"
        }
      `}
        </script>
    </Helmet>
);

export default SeoRegister;
