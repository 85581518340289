import React from "react";
import { Helmet } from "react-helmet-async";

const SeoLogin = () => (
    <Helmet>
        <html lang="es" />
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://app.aseguis.es/login/" />

        <title>Iniciar sesión - Aseguis</title>
        <meta
            name="description"
            content="Inicia sesión en Aseguis para acceder a tu cuenta y gestionar tus transportes."
        />
        <meta
            name="keywords"
            content="inicio de sesión, Aseguis, software transporte, gestión transporte"
        />
        <meta name="robots" content="index, follow" />

        {/* OpenGraph */}
        <meta property="og:title" content="Iniciar sesión - Aseguis" />
        <meta
            property="og:description"
            content="Inicia sesión en Aseguis y accede a nuestras soluciones de gestión de transporte."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://app.aseguis.es/login/" />
        <meta property="og:image" content="https://play-lh.googleusercontent.com/RsLK_NGZdnBvVpNXcUEHfA3ZzC62EOZo97JNx8RhcEBXPtKCrblj6iiu61RcX59lDNWD=w600-h300-pc0xffffff-pd" />

        {/* Twitter Cards */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Iniciar sesión - Aseguis" />
        <meta
            name="twitter:description"
            content="Accede a tu cuenta en Aseguis y empieza a gestionar tus transportes."
        />
        <meta name="twitter:image" content="https://play-lh.googleusercontent.com/RsLK_NGZdnBvVpNXcUEHfA3ZzC62EOZo97JNx8RhcEBXPtKCrblj6iiu61RcX59lDNWD=w600-h300-pc0xffffff-pd" />

        {/* Script JSON-LD para la página Login */}
        <script type="application/ld+json">
            {`
        {
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Iniciar sesión",
          "description": "Página de inicio de sesión en Aseguis.",
          "url": "https://app.aseguis.es/login/"
        }
      `}
        </script>
    </Helmet>
);

export default SeoLogin;
