import { useDispatch, useSelector } from "react-redux";
import React, { ChangeEvent, useEffect, ReactElement, useState, useRef } from "react";
import { useHistory } from 'react-router-dom';
import { allAppRoutes } from "../../common/commonInterfaces/allAppRoutes";
import { clearLoginError } from "../Login/LoginStore";
import axios from "axios";
import {
    cargaCompleta, CapitalizefirstLetter, validateEmail,
    isEmailValidate, validateAlphaNumeric, validateFormEmail, validateFormEmpty,
} from "aseguisShared";
import exampleLogo from "../../assets/exampleLogo.png";
import './Register.css';
import ReCAPTCHA from "react-google-recaptcha";
import { Notify } from "../../common/Toastify/Notify";
import { ApplicationState } from "../../ApplicationState";
import { IRegisterData, getStripeProducts, initRegisterData } from "./RegisterStore";
import RegisterPlans from "./RegisterPlans";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import RegisterTemplate from "./RegisterTemplate";
import imageLogo from "../../assets/logoSoloAseguis_2.png";
import { Modal } from "react-bootstrap";
import SeoRegister from "./RegisterSeo";

export interface PasswordRequirements {
    minLength: boolean;
    hasUppercase: boolean;
    hasLowercase: boolean;
    hasNumber: boolean;
    passwordsMatch: boolean;
}

function Register(): ReactElement {

    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const dispatch = useDispatch();
    const history = useHistory();

    const [isLoading, setIsLoading] = React.useState(false)
    const [showPlanModal, setShowPlanModal] = React.useState(false)
    const [registerFase, setRegisterFase] = useState<number>(1)
    const [isCaptchaCompleted, setIsCaptchaCompleted] = useState(false);
    const [formData, setFormData] = useState<IRegisterData>(initRegisterData)
    const [errorForm, setErrorForm] = useState(true);
    const [passwordRequirements, setPasswordRequirements] = useState<PasswordRequirements>({
        minLength: false,
        hasUppercase: false,
        hasLowercase: false,
        hasNumber: false,
        passwordsMatch: false,
    });
    const passwordIsOK =
        passwordRequirements.minLength &&
        passwordRequirements.hasUppercase &&
        passwordRequirements.hasLowercase &&
        passwordRequirements.hasNumber &&
        passwordRequirements.passwordsMatch;

    useEffect(() => {
        const convertImageToBase64 = (url: string, callback: (result: string | ArrayBuffer | null) => void) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                const reader = new FileReader();
                reader.onloadend = function () {
                    callback(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        };
        const loadImage = () => {
            convertImageToBase64(exampleLogo, (base64Image) => {
                if (typeof base64Image === 'string') {
                    const cleanedBase64Image = base64Image.replace(/^data:image\/[a-z]+;base64,/, '');
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        imageBase64: cleanedBase64Image
                    }));
                }
            });
        };
        loadImage();
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [registerFase]);

    useEffect(() => {
        dispatch(getStripeProducts())
    }, [])

    useEffect(() => {
        if (
            (formData.ciaNif.length < 7) ||
            (formData.ciaName.length < 2) ||
            (formData.ciaAddress.calle.length < 6) ||
            formData.primaryColor.length < 2 ||
            formData.secondColor.length < 2 ||
            formData.imageBase64.length < 2 ||
            formData.userNif.length < 7 ||
            formData.userName.length < 2 ||
            formData.userApe1.length < 2 ||
            !isEmailValidate(formData.userEmail) ||
            (formData.userPassword1 !== formData.userPassword2)
        ) {
            setErrorForm(true)
        } else {
            setErrorForm(false)
        }
    }, [formData]);

    const goToRegister = async () => {
        setIsLoading(true)
        dispatch(clearLoginError())
        const newFormData: IRegisterData = {
            ...formData,
            captcha: recaptchaRef.current?.getValue() || ""
        };
        const register = await axios.post<cargaCompleta>('api/register/', newFormData)
        if (
            register.status === 201 || register.status === 202 || register.status === 204 ||
            register.status === 205
        ) {
            Notify("Error al dar de alta usuario. contacte con soporte", "warn")
            history.replace(allAppRoutes.login);
        }
        if (register.status === 203) {
            Notify("Error al dar de alta usuario. Inténtelo de nuevo", "warn")
            history.replace(allAppRoutes.login);
        }
        setIsLoading(false)
    }

    return (

        <RegisterTemplate>

            <SeoRegister />

            {registerFase === 1 &&
                <div className="login-container">
                    <img src={imageLogo} alt="Logo" className="register-logo" />
                    <div className="text-center">
                        <h1 className="h3 text-gray-900 mb-2">
                            <span style={{ fontWeight: "bold" }}>
                                Prueba Aseguis 30 días gratis
                            </span>
                            <br></br>
                            <div style={{ marginTop: "10px" }}>
                                <span style={{ fontWeight: "bold", fontSize: "20px", marginTop: "10px" }}>
                                    <span className="register-discount-badge">Sin límites y sin tarjeta de crédito</span>
                                </span>
                            </div>
                            <span style={{ fontWeight: "bold", fontSize: "12px" }}>
                                <span
                                    style={{ cursor: "pointer", textDecoration: "underline" }}
                                    onClick={() => setShowPlanModal(true)}
                                >
                                    Ver planes
                                </span>
                            </span>
                        </h1>
                    </div>


                    <br></br>

                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label className="register-input firstName"
                                style={{ fontSize: "0.8rem", marginBottom: "0px" }}>Nombre</label>
                            <input
                                id="register-input emailLogin register"
                                name="firstName"
                                type="text"
                                autoComplete="off"
                                className={validateFormEmpty(formData.userName, 2)}
                                value={formData.userName}
                                required
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                                    setFormData({
                                        ...formData,
                                        userName: value
                                    })
                                }}
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="register-input firstName"
                                style={{ fontSize: "0.8rem", marginBottom: "0px" }}>Apellido</label>
                            <input
                                id="register-input emailLogin register2"
                                name="lastName"
                                type="text"
                                autoComplete="off"
                                className={validateFormEmpty(formData.userApe1, 2)}
                                value={formData.userApe1}
                                required
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    var value = CapitalizefirstLetter(validateAlphaNumeric(event.target.value))
                                    setFormData({
                                        ...formData,
                                        userApe1: value
                                    })
                                }}
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="register-input email" style={{ fontSize: "0.8rem", marginBottom: "0px" }}>Correo
                            electrónico</label>
                        <input
                            id="register-input email"
                            name="codigo"
                            autoComplete="ÑÖcompletes"
                            type="email"
                            className={validateFormEmail(formData.userEmail)}
                            required
                            value={formData.userEmail}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                var value = validateEmail(event.target.value)
                                setFormData({
                                    ...formData,
                                    userEmail: value
                                })
                            }}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="register-input password1"
                            style={{ fontSize: "0.8rem", marginBottom: "0px" }}>Contraseña</label>
                        <input
                            id="register-input password1"
                            type="password"
                            name="register-password1"
                            autoComplete="new-password"
                            className={validateFormEmpty(formData.userPassword1, 2)}
                            required
                            value={formData.userPassword1}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                const { value } = event.target;
                                setFormData({
                                    ...formData,
                                    userPassword1: value
                                })
                                setPasswordRequirements(prevRequirements => ({
                                    ...prevRequirements,
                                    minLength: value.length >= 8,
                                    hasUppercase: /[A-Z]/.test(value),
                                    hasLowercase: /[a-z]/.test(value),
                                    hasNumber: /[0-9]/.test(value),
                                    passwordsMatch: value === formData.userPassword2 && value.length > 0,
                                }));
                            }}
                        />
                    </div>

                    <div className="form-group">

                        <label htmlFor="register-input password2" style={{ fontSize: "0.8rem", marginBottom: "0px" }}>Repita
                            Contraseña</label>
                        <input
                            id="register-input password2"
                            type="password"
                            name="register-password2"
                            autoComplete="register-password2"
                            className={validateFormEmpty(formData.userPassword2, 2)}
                            required
                            value={formData.userPassword2}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                const { value } = event.target;
                                setFormData({
                                    ...formData,
                                    userPassword2: value
                                })
                                setPasswordRequirements(prevRequirements => ({
                                    ...prevRequirements,
                                    passwordsMatch: value === formData.userPassword1 && value.length > 0,
                                }));
                            }}
                        />
                        {((formData.userPassword1.length > 0 || formData.userPassword2.length > 0) && !passwordIsOK) &&
                            <div style={{ marginBottom: "-30px" }}>
                                {showPasswordRequirements(passwordRequirements)}
                            </div>
                        }
                    </div>

                    <br></br>

                    <div className="register-custom-input-container">
                        <div style={{ marginTop: "10px", height: "78px", width: "304px" }}>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LfgsN4pAAAAACRR9KJwZ-E6ZGvM8-FMPTZl6CDL"
                                onChange={(value) => {
                                    setIsCaptchaCompleted(!!value);
                                }}
                            />
                        </div>
                    </div>

                    <br></br>
                    <br></br>

                    <button
                        className={"btn btn-primary btn-user btn-block"}
                        type="button"
                        disabled={(errorForm || !isCaptchaCompleted || !passwordIsOK)}
                        onClick={async () => {
                            if (!isLoading) {
                                const recaptchaValue = recaptchaRef.current?.getValue();
                                if (!recaptchaValue) {
                                    return;
                                }
                                if (!errorForm && isCaptchaCompleted && passwordIsOK) {
                                    await goToRegister()
                                    setRegisterFase(registerFase + 1)
                                } else {
                                    Notify("Complete el formulario correctamente", "warn")
                                }
                            }
                        }}
                    >
                        {(isLoading) ?
                            <span className="spinner-border spinner-border-sm"></span>
                            :
                            <span>Registrar</span>
                        }
                    </button>

                    <div
                        className="text-center"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: "20px"
                        }}
                    >
                        <div className="form-check mb-4"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '0.9rem',
                                color: '#6c757d'
                            }}>
                            <label className="form-check-label" htmlFor="acceptTerms"
                                style={{ lineHeight: '1.2' }}>
                                Al continuar, confirmas que aceptas la
                                <a href={allAppRoutes.politicaPrivacidad} target="_blank"
                                    style={{ color: '#007bff', textDecoration: 'none', margin: '0 5px' }}
                                >
                                    política de privacidad
                                </a>y los
                                <a href={allAppRoutes.avisoLegal} target="_blank"
                                    style={{ color: '#007bff', textDecoration: 'none', margin: '0 5px' }}
                                >
                                    términos y condiciones
                                </a>
                            </label>
                        </div>
                    </div>

                </div>
            }


            {registerFase === 2 &&
                <div className="login-container">
                    <div className="text-center">
                        <div style={{ marginTop: "11px", marginBottom: "28px" }}>
                            <div className="h3 text-gray-900 mb-2 login-title">
                                Se ha enviado un email a la dirección de correo proporcionada
                            </div>
                            <br></br>
                            <div className="h4 text-gray-900 login-subtitle">
                                Haga click en el enlace para activar la cuenta y podrá iniciar sesión
                            </div>
                        </div>
                        <button
                            className={"btn btn-primary btn-user btn-block"}
                            type="button"
                            onClick={async () => {
                                history.replace(allAppRoutes.login);
                            }}
                        >
                            <span>Aceptar</span>
                        </button>
                    </div>
                </div>
            }


            {/* MODAL ELEGIR PLAN */}
            <Modal
                show={showPlanModal}
                onHide={() => setShowPlanModal(false)}
                dialogClassName="modal-xl"
            >
                <Modal.Body>
                    <RegisterPlans
                        from={"register"}
                        originalPrices={[]}
                        callbackGoBack={() => setShowPlanModal(false)}
                    />
                </Modal.Body>
            </Modal>


        </RegisterTemplate>


    );
}


export const showPasswordRequirements = (passwordRequirements: PasswordRequirements) => {
    return (
        <div className="text-center passwordRequirements">
            <ul className="register-password-requirements">
                <li style={{ color: passwordRequirements.minLength ? 'green' : 'red' }}>
                    <FontAwesomeIcon
                        icon={passwordRequirements.minLength ? faCheck : faTimesCircle} />
                    8 caracteres o más
                </li>
                <li style={{ color: passwordRequirements.hasUppercase ? 'green' : 'red' }}>
                    <FontAwesomeIcon
                        icon={passwordRequirements.hasUppercase ? faCheck : faTimesCircle} />
                    Una mayúscula
                </li>
                <li style={{ color: passwordRequirements.hasLowercase ? 'green' : 'red' }}>
                    <FontAwesomeIcon
                        icon={passwordRequirements.hasLowercase ? faCheck : faTimesCircle} />
                    Una minúscula
                </li>
                <li style={{ color: passwordRequirements.hasNumber ? 'green' : 'red' }}>
                    <FontAwesomeIcon
                        icon={passwordRequirements.hasNumber ? faCheck : faTimesCircle} />
                    Un número
                </li>
                <li style={{ color: passwordRequirements.passwordsMatch ? 'green' : 'red' }}>
                    <FontAwesomeIcon
                        icon={passwordRequirements.passwordsMatch ? faCheck : faTimesCircle} />
                    Las contraseñas coinciden
                </li>
            </ul>
        </div>
    )
}


export default Register;
